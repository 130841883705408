import mediaTitle from '../images/media-v4.png';
import MediaItem from './media-item';

function MediaSection() {
  return (
	<div id="media-section" className="container-fluid bg-black">
	 			<a className="anchor" id="band-media"></a>  		
		  	<div className="row">
			   
			    <div className="col-md-1 themed-grid-col"></div>
	        
	        <div className="col-md-10 themed-grid-col">
													
							<div id="media-title" className="text-center">
								<img id="media-title" className="img-fluid" src={mediaTitle} 
										alt="Media" data-font-name="adnp1" data-font-url="https://fontmeme.com/graffiti-creator/"
										height="137px" width="280px" style={{ maxWidth: '100%', height: 'auto'}} loading="lazy"
								/>
							</div>
							
							<div id="band-songs-content" className="row row-cols-1 row-cols-md-3 g-4">
									<MediaItem 
										title = "Scars and Regrets"
										description = "When love turns to hate and joy to despair nothing is left but scars and regret."
										link = "https://www.youtube.com/embed/A8Fo-Ler320?si=VkVcgVLwQRIOxcKd"
									/>
									
									<MediaItem 
										title = "Too much to lose"
										description = "Naja atra, also called Chinese cobra, is a highly venomous member of the true cobras"
										link = "https://www.youtube.com/embed/_E4W8LM12Uk?si=QdE6XG2xjE_9j_0_"
									/>
									
									<MediaItem 
										title = "Falling Down"
										description = "Would you fall down for money or rise up for freedom?"
										link = "https://www.youtube.com/embed/6Ab7Ha3K-4A?si=qpd5LvTPPPwt-vwf"
									/>								
							</div>

		      </div>
		        
		    <div className="col-md-1 themed-grid-col"></div>

		  	</div>

	</div>
  );
}

export default MediaSection;
