import './anouncement.css';
import anouncementTitle from '../images/next-show.png';

function AnouncementSection() {
	let cardStyle = {
		background: 'none',
		border: 'none'
	};
	
	return (
		<div>
			<a className="anchor" id="news-anchor"></a>
			<div className="container-fluid bg-light">	 
	
			<div id="anouncement" className="row">
			    <div className="col-md-1 themed-grid-col"></div>
		        <div className="col-md-10 themed-grid-col">
					<div className="text-center" id="band-members-title">
						<img id="next-show-title"
							className="img-fluid"
							alt="Announcement"
							src={anouncementTitle} 
							data-font-name="adnp1" data-font-url="https://fontmeme.com/graffiti-creator/"
							loading="lazy"/>
					</div>
					
					<div className="text-center" id="announcement-content">

						{/**
						<h5 className="card-title font-bold text-white">Our First Gig!</h5>
					    <p className="font-regular text-white"><h5>At August 15th - 8pm, Friedrichstadt-Palast, Friedrichstraße 107 10117  Berlin we are going to play for you!</h5></p>
					   */}
						<div className="card" style={cardStyle}>
						    <div className="card-body">

						      	<h5 className="card-text font-bold text-white text-center">
									<p>
										<a className="text-black" href="https://www.facebook.com/events/920002383663872/920004883663622/">
											Breaking The Silence 2025
										</a>
									</p>
									{/**<p>Friday April 19th, 2025</p>*/}
									<p>Friday April 19th - <a className="text-white" href="https://maps.app.goo.gl/vtweYaB55XYjkvPZ6">Junction Bar</a></p>
									{/**<p className="font-major">Bands:  Terra Atlantica, Astroking, Countless Skies, Zmey</p>*/}
									<p>Entrenance: from 20:30pm, Show-time: 21pm</p>
								</h5>

						    </div>
						</div>
			
					</div>
				</div>
				<div className="col-md-1 themed-grid-col"></div>
			</div>
		
			</div>
		</div>
	);
}

export default AnouncementSection;